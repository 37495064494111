















import { Component, Mixins } from 'vue-property-decorator';
import UserDto from '@/app/user/dto/user.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ListUsersDto from '../dto/list-users.dto';
import usersService from '../services/users.service';
import UsersDatatable from '../components/users-datatable.vue';

@Component({
  components: {
    UsersDatatable,
  },
  metaInfo(this: Users) {
    return { title: this.$t('pageTitle.users').toString() };
  },
})
export default class Users extends Mixins(HandlesErrorMixin) {
  private loading = true;
  private users: UserDto[] = [];
  private totalUsers = 0;
  private filters: ListUsersDto = {
    q: '',
    page: 1,
    itemsPerPage: 10,
  };

  onSearch(q: string) {
    this.filters.q = q;

    this.loadUsers();
  }

  onPageChange(page: number) {
    this.filters.page = page;

    this.loadUsers();
  }

  onUpdateItemsPerPage(itemsPerPage: number) {
    this.filters.page = 1;
    this.filters.itemsPerPage = itemsPerPage;

    this.loadUsers();
  }

  async loadUsers() {
    this.loading = true;

    try {
      const response = await usersService.listUsers(this.filters);

      this.users = response.data.items;
      this.totalUsers = response.data.totalItems;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.loadUsers();
  }
}
