
































































































































import { DataTableHeader } from 'vuetify';
import { Component, Emit, Mixins } from 'vue-property-decorator';
import UserDto from '@/app/user/dto/user.dto';
import Avatar from '@/app/core/components/avatar.vue';
import DatatableMixin from '@/app/core/mixins/datatable.mixin';
import RegisterUserDto from '../dto/register-user.dto';
import RegisterUserDialog from './register-user-dialog.vue';
import DeletesUserMixin from '../mixins/deletes-user.mixin';
import ResendsRegistrationEmailMixin from '../mixins/resends-registration-email.mixin';

@Component({
  components: {
    Avatar,
  },
})
export default class UsersDatatable extends Mixins(
  DatatableMixin,
  DeletesUserMixin,
  ResendsRegistrationEmailMixin,
) {
  declare items: UserDto[];

  private headers: DataTableHeader[] = [
    {
      value: 'picture',
      text: this.$t('label.picture').toString(),
      class: 'visible-on-mobile-only',
    },
    { value: 'name', text: this.$t('label.name').toString() },
    { value: 'email', text: this.$t('label.email').toString() },
    { value: 'role', text: this.$t('label.role').toString() },
    { value: 'actions', text: this.$t('label.actions').toString() },
  ];

  get transformedItems() {
    return this.items.map((item: UserDto) => {
      const firstname = item.profile?.firstname;
      const lastname = item.profile?.lastname;

      return {
        id: item.id,
        picture: item.profile?.picture,
        name: (firstname && lastname) ? `${firstname} ${lastname}` : '',
        email: item.email,
        role: item.role,
        active: item.active,
      };
    });
  }

  async openRegisterDialog() {
    const success = await this.$dialog.open<RegisterUserDto>(RegisterUserDialog);

    if (success) this.userRegistered(success);
  }

  @Emit('userRegistered')
  userRegistered(dto: RegisterUserDto) {
    return dto;
  }
}
